export const moduleSlug = 'dashboard'
export const moduleAuth = 'Dashboard';

export const children = [
  {
    path: "",
    component: () => import("./pages/MainDashboard.vue"),
    meta: {
      title: "Dashboard",
      linkTitle: "Dashboard",
      linkIcon: "home",
      showInNav: true,
      permission: "Dashboard.Dashboard",
    },
  },
  {
    path: "store-summary",
    component: () => import("./pages/StoreSummary.vue"),
    meta: {
      title: "Store Summary",
      linkTitle: "Store Summary",
      linkIcon: "file-text",
      showInNav: true,
      permission: "Transactions.StoreSummaryReport",
    },
  },
  {
    path: "store-status",
    component: () => import("./pages/StoreStatus.vue"),
    meta: {
      title: "Store Status",
      linkTitle: "Store Status",
      linkIcon: "file-text",
      showInNav: true,
      permission: "Dashboard.StoreStatus",
    },
  },
  {
    path: "reports",
    meta: {
      title: "Other Report",
      linkTitle: "Other Report",
      linkIcon: "database",
      showInNav: true,
      excludeInRoute: true,
      menu: [
        {
          path: "transactions",
          title: "Transaction Reports",
          permission: "Transactions.TransactionReport",
        },
        {
          path: "products",
          title: "Product wise Sales Report",
          permission: "Transactions.ProductReport",
        },
        {
          path: "sales",
          title: "Sales Summary",
          permission: "Transactions.SalesSummaryReport",
        },
      ],
    },
  },
  {
    path: "products",
    component: () => import("./pages/ItemWiseSalesReport.vue"),
    meta: {
      title: "Product wise Sales Report",
      linkTitle: "Productwise Sales Report",
      linkIcon: "home",
      showInNav: false,
      permission: "Transactions.ProductReport",
    },
  },
  {
    path: "sales",
    component: () => import("./pages/SalesSummary.vue"),
    meta: {
      title: "Sales Summary",
      linkTitle: "Sales Summary",
      linkIcon: "bar-chart",
      showInNav: false,
      permission: "Transactions.SalesSummaryReport",
    },
  },

  {
    path: "transactions",
    component: () => import("./pages/Transactions.vue"),
    meta: {
      title: "Transaction Reports",
      linkTitle: "Transaction Reports",
      linkIcon: "database",
      showInNav: false,
      permission: "Transactions.TransactionReport",
    },
  },
];
