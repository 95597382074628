import { actionUrl } from "@/apiClient";
export const moduleSlug = 'experience';
export const moduleAuth = 'Experience';

export const children = [
  {
    path: "",
    redirect: to => {
      return to.path + '/' + 'feedbacks';
    },
    meta: {
    },
  },
  {
    path : 'feedbacks',
    component: () => import('./pages/FeedbackList.vue'),
    meta: {
      title: 'Experience List',
      linkTitle: 'Feedback',
      linkIcon: 'bar-chart',
      showInNav: true,
      permission: 'Experience.Feedback',
    }
  },
  {
    path: 'summary',
    component: () => import('./pages/FeedbackOverview.vue'),
    meta: {
      title: 'Feedback Summary',
      linkTitle: 'Summary',
      linkIcon: 'eye',
      showInNav: true,
      permission: 'Experience.Summary',
    }
  },
  {
    path: 'nps-analysis',
    component: () => import('./pages/FeedbackReward.vue'),
    meta: {
      title: 'NPS Analysis',
      linkTitle: 'NPS Analysis',
      linkIcon: 'tachometer',
      showInNav: true,
      permission: 'Experience.NpsAnalysis',
    }
  },
 
  {
    path: 'report',
    component: () => import('./pages/FeedbackDetailedReport.vue'),
    meta: {
      title: 'Feedback Report',
      linkTitle: 'Feedback Report',
      linkIcon: 'file-text',
      showInNav: true,
      permission: 'Experience.FeedbackReport',
    }
  },
  {
    path: 'productwise-overview',
    component: () => import('./pages/FeedbackProductWiseOverview.vue'),
    meta: {
      title: 'Feedback Product Wise Overview',
      linkTitle: 'Products',
      linkIcon: 'database',
      showInNav: true,
      permission: 'Experience.Products',
    }
  },
  {
    path: 'Staff',
    component: () => import('./pages/FeedbackStaff.vue'),
    meta: {
      title: 'Feedback Staff',
      linkTitle: 'Staff',
      linkIcon: 'user',
      showInNav: true,
      permission: 'Experience.Staff',
    }
  },
  {
    path: 'config',
    component: () => import('@/components/Configuration/Configurations.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Configurations',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: 'Experience.Feedback',
    }
  }
];
export const quickLinks = [
   {
    name: 'Feedback',
    link: actionUrl + 'merchant/:id/feedback',
    permission: 'Experience.Config.Feedback',
  },
   {
    name: 'Feedback Suggestions',
    link: actionUrl + 'merchant/:id/feedback-suggestions',
    permission: 'Experience.Config.FeedbackSuggestions',
  },
    {
    name: 'Report Settings',
    link: actionUrl + 'merchant/:id/reports-settings',
    permission: 'Experience.Config.ReportSettings',
  },
  {
    name: 'Configurations',
    link: `/${moduleSlug}/config`,
    new: true,
    brandFlag: 'enableConfigurations',
    permission: 'Experience.Feedback',
  },
];
