import { actionUrl } from "@/apiClient";

// ! INSTRUCTIONS REGARDING `MODULES.*.PERMISSION`
// - the string has to match any string present in the slugs column of the permissions table in DB

export const modules = [
    {
        name: "Dashboard",
        slug: "dashboard",
        path: "/dashboard",
        description: "Know your customers deeper with their preferences",
        image: new URL(`../assets/dashboard-normal.svg`, import.meta.url).href,
        SelectedImage: new URL(`../assets/dashboard-selected.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: "Dashboard",
    },
    {
        name: "Loyalty",
        slug: "loyalty",
        path: "/loyalty",
        description: "Build true Loyalty with emotional and contextual rewards",
        image: new URL(`../assets/Loyalty-icon.svg`, import.meta.url).href,
        SelectedImage: new URL(`../assets/Newloyality.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: "Loyalty",
    },
    {
        name: "Customers",
        slug: "customers",
        path: "/customers",
        description: "Know your customers deeper in 360-degree & preferences",
        image: new URL(`../assets/Customers-icon.svg`, import.meta.url).href,
        SelectedImage: new URL(`../assets/Newcustomers.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: "Customers",
    },
    {
        name: "Engagement",
        slug: "engagement",
        path: "/engagement",
        description: "Drive Personalized Campaigns that keeps customers engaged",
        image: new URL(`../assets/Campaign-icon.svg`, import.meta.url).href,
        SelectedImage: new URL(`../assets/Newengagement.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: "Engagement",
    },
    {
        name: "Experience",
        slug: "experience",
        path: "/experience",
        description: "Track and enhance customer experience in real time",
        image: new URL(`../assets/Oldfeedback.svg`, import.meta.url).href,
        SelectedImage: new URL(`../assets/Newfeedback.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: "Experience",
    },
    {
        name: "Offer Engine",
        slug: "offers",
        path: "/offers",
        description: "Create and manage dynamic offers to get customers hooked",
        image: new URL("../assets/offerBox.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/newOfferBox.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: 'Offers',
    },
    {
        name: "Alerts",
        slug: "alerts",
        path: "/alerts",
        description: "Create and manage dynamic offers to get customers hooked",
        image: new URL("../assets/alerts.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/newAlerts.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: "Alerts",
    },
    {
        name: "Seamless",
        slug: "seamless",
        path: "/seamless",
        description: "Manage your online orders, users and transactions",
        image: new URL("../assets/Seamless.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/Newseamless.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: "Seamless",
    },
    {
        name: "Gifting",
        slug: "gifting",
        path: "/gifting",
        description: "Create & Manage your own branded gift cards",
        image: new URL("../assets/gifting.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/newGifting.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: 'Gifting',
    },
    {
        name: "Referral",
        slug: "referral",
        path: "/referral",
        description: "Turn your customers to brand advocates & acquire new customers",
        image: new URL("../assets/Referral-icon.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/Referral-icon.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: 'Referral',
    },
    {
        name: "Imports",
        slug: "imports",
        path: "/imports",
        description: "Bulk Import customers, trasactions or other data.",
        image: new URL("../assets/imports.svg", import.meta.url).href,
        SelectedImage: new URL("../assets/newImports.svg", import.meta.url).href,
        enable: true,
        page: true,
        permission: "Imports",
    },
    {
        name: "Configurations",
        slug: "configuration",
        path: "/" + actionUrl.slice(8) + "business/merchants",
        description: "Configurations",
        image: new URL("../assets/Configuration-icon.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/Newcofirugations.svg`, import.meta.url).href,
        enable: true,
        permission: "Configurations",
    },
    {
        name: "Content Library",
        slug: "contentLibrary",
        path: "/" + actionUrl.slice(8) + "business/websites",
        description: "Manage content of your microsites & SDKs",
        image: new URL("../assets/ContentLibrary.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/Newcontent lib.svg`, import.meta.url).href,
        enable: true,
        permission: "ContentManagement",
    },
    {
        name: "Stride",
        slug: "stride",
        path: "stride",
        description: "Manage your own last mile delivery fleet",
        image: new URL("../assets/Stride-icon.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/Newstrid.svg`, import.meta.url).href,
        enable: true,
        permission: 'Stride',
    }, 
    {
        name: "Cogent POS",
        slug: "pos",
        path: "/" + actionUrl.slice(8) + "pos",
        description: "The nextgen POS system for your business",
        image: new URL("../assets/pos.png", import.meta.url).href,
        SelectedImage: new URL(`../assets/pos.png`, import.meta.url).href,
        enable: true,
        permission: 'Cogent',
    },
    {
        name: "User Management",
        slug: "user-management",
        path: "/user-management",
        description: "Manage roles and permissions here.",
        image: new URL("../assets/userManagement.svg", import.meta.url).href,
        SelectedImage: new URL("../assets/newUserManagement.svg", import.meta.url).href,
        enable: true,
        page: true,
        permission: "UserManagement",
    },
    {
        name: "Old Dashboard",
        slug: "oldDashboard",
        path: "/" + actionUrl.slice(8) + "business/dashboard",
        description: "Missing anything from our old experience? Check here",
        image: new URL("../assets/old-dashboard.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/old-dashboard-selected.svg`, import.meta.url).href,
        enable: true,
        permission: "OldDashboard",
    },
    {
        name: "Wallet",
        slug: "wallet",
        path: "/wallet",
        description: "Wallet",
        image: new URL("../assets/Referral-icon.svg", import.meta.url).href,
        SelectedImage: new URL(`../assets/Referral-icon.svg`, import.meta.url).href,
        enable: true,
        page: true,
        permission: "Wallet",
    },
   
];
