export const moduleSlug = 'alerts'
export const moduleAuth = 'Alerts';

export const children = [
  {
    path: '',
    component: () => import('./pages/AlertsDashboard.vue'),
    meta: {
      title: 'Alerts Dashboard',
      linkTitle: 'Dashboard',
      linkIcon: 'home',
      showInNav: true,
      permission: 'Alerts.Dashboard',
    }
  },
  {
    path: 'create',
    component: () => import('./pages/AlertCreateEdit.vue'),
    meta: {
      title: 'Create Alert',
      linkTitle: 'Create Alert',
      linkIcon: 'home',
      showInNav: false,
      permission: 'Alerts.Dashboard.Write',
    }
  },
  {
    path: 'edit/:id',
    component: () => import('./pages/AlertCreateEdit.vue'),
    meta: {
      title: 'Update Alert',
      linkTitle: 'Update Alert',
      linkIcon: 'home',
      showInNav: false,
      permission: 'Alerts.Dashboard.Write',
    }
  },
  {
    path: 'manage',
    component: () => import('./pages/EmailTemplate.vue'),
    meta: {
      title: 'Email Templates',
      linkTitle: 'Email Templates',
      linkIcon: 'envelope',
      showInNav: true,
      permission: 'Alerts.EmailTemplates',
    }
  },
  {
    path: 'messages/outgoing',
    component: () => import('./pages/OutgoingMessages.vue'),
    meta: {
      title: 'Outgoing Messages',
      linkTitle: 'Outgoing Messages',
      linkIcon: 'commenting',
      showInNav: true,
      permission: 'Alerts.Messages.Outgoing',
    }
  },
  {
    path: 'messages/incoming',
    component: () => import('./pages/IncomingMessages.vue'),
    meta: {
      title: 'Incoming Messages',
      linkTitle: 'Incoming Messages',
      linkIcon: 'commenting',
      showInNav: true,
      permission: 'Alerts.Messages.Incoming',
    }
  },
  {
    path: 'insights',
    component: () => import('./pages/AlertsGraph.vue'),
    meta: {
      title: 'Insights',
      linkTitle: 'Insights',
      linkIcon: 'line-chart',
      showInNav: true,
    }
  },
];
