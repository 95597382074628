export const moduleSlug = 'customers'
export const moduleAuth = 'Customers';

export const children = [
  {
    path: "",
    component: () => import("./pages/CustomersSingleView.vue"),
    meta: {
      title: "Customers",
      linkTitle: "Customers",
      linkIcon: "users",
      showInNav: true,
      permission: "Customers.Customers",
    },
  },
  {
    path: "funnel-report",
    component: () => import("./pages/CustomersFunnelReport.vue"),
    meta: {
      title: "Funnel Report",
      linkTitle: "Funnel Report",
      linkIcon: "filter",
      showInNav: true,
      permission: "Customers.FunnelReport",
    },
  },
  {
    path: "visit-insights",
    component: () => import("./pages/VisitInsights.vue"),
    meta: {
      title: "Visit Insights",
      linkTitle: "Insights",
       linkIcon: "bar-chart",
      showInNav: true,
      permission: "Customers.Insights",
    },
  },
  {
    path: "calendar",
    component: () => import("./pages/CustomersCalendar.vue"),
    meta: {
      title: "Customer Calendar",
      linkTitle: "Calendar",
      linkIcon: "calendar",
      showInNav: true,
      permission: "Customers.Calendar",
    },
  },
  {
    path: "blocker-users",
    component: () => import("./pages/BlockerUsers.vue"),
    meta: {
      title: "Blocker Users",
      linkTitle: "Blocked Users",
      linkIcon: "user-times",
      showInNav: true,
      permission: "Customers.BlockedUsers",
    },
  },
  {
    path: "customer-subscribers",
    component: () => import("./pages/OptInOptOut.vue"),
    meta: {
      title: "Subscribers",
      linkTitle: "Subscribers",
      linkIcon: "user-plus",
      showInNav: true,
      permission: "Customers.Subscribers",
    },
  },
  {
    path: "segments",
    component: () => import("./pages/segment/SegmentList.vue"),
    meta: {
      title: "Customer Segments",
      linkTitle: "Segment",
      linkIcon: "list-alt",
      showInNav: true,
      permission: "Customers.Segments",
    },
  },
  {
    path: "segment-create",
    name: 'segment-create',
    component: () => import("./pages/segment/SegmentCreateEdit.vue"),
    meta: {
      title: "Segment Create",
      linkTitle: "Segment Create",
      linkIcon: "home",
      showInNav: false,
      permission: "Customers.Segments.CreateAndEdit",
    },
  },
  {
    path: "segment/:id/edit",
    component: () => import("./pages/segment/SegmentCreateEdit.vue"),
    meta: {
      title: "Segment Edit",
      linkTitle: "Segment Edit",
      linkIcon: "home",
      showInNav: false,
      permission: "Customers.Segments.CreateAndEdit",
    },
  },
  {
    path: "segment-overview/:id",
    component: () => import("./pages/segment/SegmentOverview.vue"),
    meta: {
      title: "Segment Overview",
      linkTitle: "Segment Overview",
      linkIcon: "home",
      showInNav: false,
      permission: "Customers.Segments.Overview",
    },
  },
  {
    path: "list",
    component: () => import("./pages/list.vue"),
    meta: {
      title: " List",
      linkTitle: "List",
      linkIcon: "list",
      showInNav: true,
      permission: "Customers.List",
    },
  },
  {
    path: "list/filter",
    component: () => import("./pages/CustomerListFilter.vue"),
    meta: {
      title: " Filter",
      linkTitle: "Filter",
      linkIcon: "search",
      showInNav: true,
      permission: "Customers.Filter",
    },
  },
  {
    path: "segment-customer-list/:id",
    component: () => import("./pages/CustomerListFilter.vue"),
    meta: {
      title: "Customer List",
      linkTitle: "Customer List",
      linkIcon: "list",
      showInNav: false,
      permission: "Customers.Filter",
    },
  },
  {
    path: "rfm",
    component: () => import("./pages/rfm/RfmList.vue"),
    meta: {
      title: "RFM",
      linkTitle: "RFM",
      linkIcon: "list",
      showInNav: false,
    },
  },
  {
    path: "rfm/create",
    component: () => import("./pages/rfm/RfmCreate.vue"),
    meta: {
      title: "RFM - Create",
      linkTitle: "RFM",
      linkIcon: "list",
      showInNav: false,
    },
  },
  {
    path: "rfm/edit/:id",
    component: () => import("./pages/rfm/RfmCreate.vue"),
    meta: {
      title: "RFM - Update",
      linkTitle: "RFM",
      linkIcon: "list",
      showInNav: false,
    },
  },
  {
    path: "rfm/analytics/:id",
    component: () => import("./pages/rfm/RfmAnalytics.vue"),
    meta: {
      title: "RFM - Analytics",
      linkTitle: "RFM",
      linkIcon: "list",
      showInNav: false,
    },
  },

  
];
