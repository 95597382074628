<template>
  <fr-header-module-selector :modules="filteredModules"
    :selected-module="allModules.find((mod) => mod.slug.includes(selectedModule))" :persistent="persistent"
    :moduleDialog="moduleDialog && this.selectedBrandId" @modal-opened="whenModalOpened" @modal-closed="resetOnClose"
    :highlightedModuleIndex="highlightedModuleIndex">
    <template v-slot:search-bar>
      <input type="text" ref="searchBar" placeholder="Search" class="form-control bg-light my-1 ms-1"
        style="border-radius:8px; max-width: 280px; margin-top: -62px !important;" v-model="searchModuleName" />
      <!-- <fr-portlet v-if="navresultdata" v-for="child in navresultdata[0].children" class="mx-1 p-2 text-start" style="margin-top: 30px; margin-bottom: -20px;">
        {{ child.meta.title }}
      </fr-portlet> -->
      <div v-if="!filteredModules.length" style="margin-top: 50px;">No Results</div>
    </template>
  </fr-header-module-selector>
</template>
<script>
import { FrHeaderModuleSelector } from "@froogal.ai/ui";
import { modules } from "../components/Modules";
import { usePermissionStore } from "@/stores/permissions";
import { useBrandsStore } from "@/stores/brands";
import { storeToRefs } from "pinia";
import Fuse from "fuse.js";
export default {
  setup() {
    const brandStore = useBrandsStore();
    const permissionStore = usePermissionStore();
    const { permissionLoader } = storeToRefs(permissionStore);
    const { selectedBrandUri, selectedBrandId } = storeToRefs(brandStore);
    const { fetchPermissions, checkAuthorization, permissions } = permissionStore;
    return {
      permissions, // ideally should be entirely replaced by checkAuthorization
      selectedBrandUri,
      fetchPermissions,
      selectedBrandId,
      permissionLoader,
      checkAuthorization,
    }
  },
  props: ["selectedModule", "persistent", "moduleDialog", "permission"],
  data() {
    return {
      searchModuleName: "",
      highlightedModuleIndex: -1,
    };
  },
  components: {
    FrHeaderModuleSelector,
  },
  computed: {
    filteredModules: function () {
      if (this.searchModuleName) {
        const fuse = new Fuse(this.allModules, {
          keys: ['name'],
          threshold: 0.4, // Adjust this threshold as needed
        });
        return fuse.search(this.searchModuleName).map(result => result.item);
      }
      return this.allModules;
    },
    allModules: function () {
      return modules.map(m => {
        if (m.page) {
          m.fullPath = this.selectedBrandUri + m.path;
        } else {
          m.fullPath = m.path;
        }
        m.enable = this.checkAuthorization(m.permission, m.slug) ? true : false;
        return m;
      });
    },
  },
  created() {
    if (this.selectedBrandId) {
      this.fetchPermissions();
    }
  },
  watch: {
    selectedBrandId() {
      if (this.selectedBrandId) {
        this.fetchPermissions();
      }
    },
    permissions() {
      this.verifyBrandModuleAccess();
    },
    searchModuleName(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.highlightedModuleIndex = 0;
      } else if (!newValue) {
        this.highlightedModuleIndex = -1;
      }
    },
  },
  mounted() {
    if (this.permissions && this.selectedModule) {
      this.verifyBrandModuleAccess();
    }
  },
  methods: {
    verifyBrandModuleAccess() {
      if (this.selectedModule) {
        if (!this.checkAuthorization(this.permission, this.selectedModule)) {
          window.open("/" + this.$route.params.brand + "/", '_self');
          // this.$router.push("/" + this.$route.params.brand + "/");
        }
      }
    },
    whenModalOpened() {
      window.addEventListener('keydown', this.handleKeyDown);
      this.$nextTick(() => {
        const searchBar = this.$refs.searchBar;
        if (searchBar) {
          searchBar.focus();
        }
      });
    },
    resetOnClose() {
      this.searchModuleName = "";
      window.removeEventListener('keydown', this.handleKeyDown);
    },
    handleKeyDown(event) {
      const searchBar = this.$refs.searchBar;
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        if (document.activeElement === searchBar) {
          event.preventDefault();
        }
      }

      if (event.key === 'ArrowLeft') {
        if (this.highlightedModuleIndex > 0) {
          this.highlightedModuleIndex -= 1;
        }
      } else if (event.key === 'ArrowRight') {
        if (this.highlightedModuleIndex < this.filteredModules.length - 1) {
          this.highlightedModuleIndex += 1;
        }
      }
    },
  }
};
</script>