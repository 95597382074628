<template>
    <div class="d-flex align-items-center justify-content-center py-5 my-5" v-if="permissionLoader">
        <i class="fa fa-spinner fa-4x fa-pulse py-5 my-5" />
    </div>
    <Error403Page v-else-if="!checkAuthorization(permission, selectedModule)"></Error403Page>
    <div v-else style="margin-top: -1px;">
        <fr-header-nav class="sticky-top" :class="class" style="z-index: 2" :quicklinks="configurationLinks">
            <template v-slot:picker>
                <component :is="picker"></component>
            </template>
            <template v-for="link in links" :key="link.path">
                <fr-header-nav-item v-if="!link.meta.excludeInRoute"
                    :link="getLink(selectedBrandUri, selectedModule, link.path)" :id="link.name">
                    <i v-if="link.meta.linkIcon" class="fa" :class="'fa-' + link.meta.linkIcon"></i>
                    {{ link.meta.linkTitle || link.meta.title }}
                </fr-header-nav-item>
                <fr-dropdown v-if="link.meta.excludeInRoute && menuLinks(link.meta.menu).length">
                    <fr-dropdown-heading no-caret auto-close="outside">
                        <span class="text-white dropdown-toggle d-flex align-items-center nav-link" type="button"
                            :class="activeClass(link.path) ? 'router-link-active active' : ''">
                            <i v-if="link.meta.linkIcon" class="fa" :class="'fa-' + link.meta.linkIcon"></i>&nbsp;
                            {{ link.name || link.meta.title }}
                        </span>
                    </fr-dropdown-heading>
                    <fr-dropdown-group class="dropdown-menu-right">
                        <fr-dropdown-options v-for="menu in menuLinks(link.meta.menu)" :key="menu"
                            :class="activeClass(menu) ? 'bg-purple' : ''" class="dropdownOption p-0">
                            <router-link :to="'/' + selectedBrandUri + '/' + selectedModule + '/' + menu.path"
                                class="text-decoration-none customer-color fw-normal btn-sm  w-100 btn text-start border-0 rounded-0">
                                {{ menu.title }}
                            </router-link>
                        </fr-dropdown-options>
                    </fr-dropdown-group>
                </fr-dropdown>
            </template>

            <template v-slot:config>
                <div v-if="configurationLinks && configurationLinks.length != 0">
                    <fr-dropdown class="w-100">
                        <fr-dropdown-heading light-caret auto-close="outside"
                            class="w-100 d-flex align-items-center py-2">
                            <a class="nav-link text-white" href="#">
                                Config
                            </a>
                        </fr-dropdown-heading>
                        <fr-dropdown-group>
                            <template v-for="item in configurationLinks" :key="item">
                                <router-link :to="{ path: item.link }" class="dropdown-item" v-if="item.selfPage">
                                    {{ item.name }}
                                </router-link>
                                <fr-dropdown-options target='_blank' :href="item.link" v-else>
                                    {{ item.name }}
                                </fr-dropdown-options>

                            </template>

                        </fr-dropdown-group>
                    </fr-dropdown>
                </div>
            </template>

        </fr-header-nav>
        <div v-if="checkAuthorization(this.$route.meta.permission)" class="displaySizeScroller">
            <div :style="{ 'min-width': screenWidth + 'px' }">
                <router-view></router-view>
            </div>
        </div>
        <Error403Page v-else></Error403Page>
    </div>
    <div class="previewMsgBox d-flex d-md-block d-lg-none" style="z-index:9999">
        <div style="font-size: 14px;">
            The dashboard is not yet optimized for Mobile devices. For best experience kindly use Desktop or Laptop.
        </div>
    </div>
</template>
<script>
import {
    FrHeaderNavItem,
    FrHeaderNav,
    FrDropdown,
    FrDropdownGroup,
    FrDropdownHeading,
    FrDropdownOptions
} from "@froogal.ai/ui";
import { storeToRefs } from "pinia";
import { useBrandsStore } from '@/stores/brands';
import { useMerchantsStore } from "@/stores/merchants";
import { useUserProfileStore } from "@/stores/user-profile";
import { usePermissionStore } from "@/stores/permissions";
import Error403Page from "../views/Error403Page.vue";
export default {
    name: 'module-layout',
    setup() {
        const brandStore = useBrandsStore();
        const merchantList = useMerchantsStore();
        const { selectedBrandUri, selectedBrandId, selectedBrand } = storeToRefs(brandStore);
        const { selectedBrandMerchants } = storeToRefs(merchantList);
        const { user } = useUserProfileStore();
        const permissionStore = usePermissionStore();
        const { permissionLoader, isBrandAdmin } = storeToRefs(permissionStore);
        const { checkAuthorization } = permissionStore;
        return {
            selectedBrandUri,
            selectedBrandId,
            selectedBrand,
            selectedBrandMerchants,
            user,
            permissionLoader,
            checkAuthorization,
            isBrandAdmin
        };
    },
    data() {
        return {
            showErrorInfo: true,
            screenWidth: screen.width < 500 ? (screen.width * 2 - 30) : '1000',
        }
    },
    props: ["subNavigation", "quickLinks", "selectedModule", "class", "picker", "permission"],
    components: {
        FrHeaderNavItem,
        FrHeaderNav,
        FrDropdown,
        FrDropdownGroup,
        FrDropdownHeading,
        FrDropdownOptions,
        Error403Page,
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    computed: {
        links() {
            return this.subNavigation.filter((a) => {
                if (a.meta.brandUser) {
                    return this.isBrandAdmin
                } else {
                    return (a.meta.showInNav || (a.meta.isAdmin ? this.user.isAdmin : false)) && (this.checkAuthorization(a.meta.permission) || a.meta.excludeInRoute);
                }
            }).filter( ele => ele.meta.brandFlag ? this.selectedBrand.flags[ele.meta.brandFlag] : true );
        },
        base() {
            return `/${this.selectedBrandUri}/${this.selectedModule}`;
        },
        configurationLinks() {

            return this.quickLinks?.filter(a => this.checkAuthorization(a.permission))
            .filter( a =>  a.brandFlag ? this.selectedBrand.flags[a.brandFlag] : true)
            .map(m => {
                if (m.new) {
                    return {
                        name: m.name,
                        link: '/' + this.selectedBrandUri + m.link,
                        selfPage: true,
                    }
                } else {
                    return {
                        name: m.name,
                        link: this.selectedBrandMerchants.length != 0
                            ? m.link.replace(":id", this.selectedBrandMerchants[0]?.id)
                            : m.link,
                    };
                }
            })
        },
    },
    methods: {
        menuLinks(list) {
            return list.filter(a =>
                (this.checkAuthorization(a.permission))
            );
        },
        myEventHandler() {
            this.screenWidth = screen.width < 500 ? (screen.width * 2 - 30) : '1000';
        },
        activeClass(item) {
            const itemPath = this.base + (item.path ? '/' + item.path : '');
            if (itemPath == this.$route.path) {
                return true;
            }
        },
        getLink(uri, module, path) {
            if (path) {
                return '/' + uri + '/' + module + '/' + path;
            } else {
                return '/' + uri + '/' + module;
            }
        }
    }
};
</script>
<style>
.customer-color,
.customer-color:hover {
    color: black;
}

.bg-purple,
.bg-purple:hover,
.bg-purple .customer-color,
.dropdownOption:active {
    background: #59348b !important;
    color: white !important;
}

.previewMsgBox {
    background-color: #d64135;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    position: fixed;
    bottom: 5px;
    left: 5px;
    right: 5px;
}

@media screen and (max-width: 1000px) {
    .displaySizeScroller {
        overflow: auto;
    }
}
</style>
