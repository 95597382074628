<template>
  <div class="p-2 bg-white mx-2 rounded-4 text-start my-2">
    <div
      class="text-center w-100 dropdown-option-items"
      :style="packageDetails.length > 2 ? 'height:200px' : ''"
    >
      <table class="table">
        <tbody>
          <tr v-for="pack in packageDetails" :key="pack">
            <td class="m-0 p-0">
              <table class="table text-secondary text-end pe-2">
                <thead class="bg-light text-dark">
                  <th colspan="2" class="text-start px-2">
                    {{ pack.packageName }}
                  </th>
                </thead>
                <tr>
                  <td class="text-start">
                    <li class="fa fa-comment"></li>
                    SMS
                  </td>
                  <td class="px-2">{{ pack.smsCredits }}</td>
                </tr>
                <tr>
                  <td class="text-start">
                    <li class="fa fa-envelope"></li>
                    Email
                  </td>
                  <td class="px-2">{{ pack.emailCredits }}</td>
                </tr>
                <tr>
                  <td class="text-start">
                    <li class="fa fa-whatsapp"></li>
                    Whatsapp
                  </td>
                  <td class="px-2">{{ pack.whatsappCredits }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr class="m-1" />
    <div data-v-01b96e43="" class="text-center fw-bolder w-100">
      <button
        data-v-01b96e43=""
        class="btn btn-sm fw-bolder"
        @click="showPackageDetails()"
      >
        Show Store Credits
      </button>
    </div>
  </div>
  <fr-modal
    :value="packageDialog"
    @cancel="packageDialog = false"
    @hidden="packageDialog = false"
    :noPaddingBody="true"
    :verticalCentered="false"
    :showFooter="false"
    :xlarge="true"
  >
    <fr-portlet class="accordion accordion-flush" id="accordionFlush">
      <fr-portlet-header bordered>
        <fr-portlet-heading :loading="tableLoader"
          >Store Credits</fr-portlet-heading
        >
        <fr-portlet-item class="mx-2">
          <fr-date-range-picker
            v-model="PackageDateRange"
          ></fr-date-range-picker>
        </fr-portlet-item>
        <fr-portlet-item class="">
          <input
            type="text"
            placeholder="Search"
            class="form-control"
            v-model="packageSearch"
            style="max-width: 240px"
          />
        </fr-portlet-item>
        <fr-portlet-item>
          <button
            class="btn mx-3 btn-close"
            @click="packageDialog = false"
          ></button>
        </fr-portlet-item>
      </fr-portlet-header>
      <table class="table text-center table-bordered">
        <thead class="bg-light">
          <tr>
            <th rowspan="2" class="text-start">Name</th>
            <th colspan="2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="16px"
              >
                <path
                  d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM144 272a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm144-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm80 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                />
              </svg>
              &nbsp; SMS
            </th>
            <th colspan="2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="16px"
              >
                <path
                  d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
                />
              </svg>
              &nbsp; EMAIL
            </th>
            <th colspan="2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width="16px"
              >
                <path
                  d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                />
              </svg>
              &nbsp; WHATSAPP
            </th>
            <th rowspan="2"></th>
          </tr>
          <tr>
            <th>Used</th>
            <th>Available</th>
            <th>Used</th>
            <th>Available</th>
            <th>Used</th>
            <th>Available</th>
          </tr>
        </thead>
        <template v-if="tableLoader">
          <tr>
            <td colspan="8">
              <div class="py-5 my-5">
                <i class="fa fa-spinner fa-pulse fa-4x"></i>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="!tableLoader && packageDetailList.length != 0">
          <template
            v-if="filteredPackages != 0"
            v-for="(pack, index) in filteredPackages"
            :key="pack"
          >
            <tbody>
              <tr  class="border border-1">
                <th class="text-start">{{ pack.packageName ?? "-" }}</th>
                <th>{{ pack.stores ? getSum(pack.stores, "sms") : 0 }}</th>
                <th>{{ pack.smsCredits ?? 0 }}</th>

                <th>{{ pack.stores ? getSum(pack.stores, "email") : 0 }}</th>
                <th>{{ pack.emailCredits ?? 0 }}</th>

                <th>{{ pack.stores ? getSum(pack.stores, "whatsapp") : 0 }}</th>
                <th>{{ pack.whatsappCredits ?? 0 }}</th>
                <th>
                  <div class="pe-2" style="max-width: 16px" v-if="pack.stores != 0">
                    <i
                      class="package accordion-button collapsed py-0"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#packagecollapse' + index"
                      aria-expanded="false"
                    >
                    </i>
                  </div>
                </th>
              </tr>
            </tbody>
            <tbody
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
              :id="'packagecollapse' + index"
            >
              <tr
                v-if="pack.stores"
                v-for="dd in pack.stores"
                :key="dd"
                class="bg-white accordion-body"
              >
                <td>{{ dd[1][1]?.merchantName ?? "-" }}</td>
                <td>{{ dd.length != 0 ? getData(dd[1], "sms") : "0" }}</td>
                <td></td>
                <td>{{ dd.length != 0 ? getData(dd[1], "email") : "0" }}</td>
                <td></td>
                <td>{{ dd.length != 0 ? getData(dd[1], "whatsapp") : "0" }}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td colspan="7" class="p-0"></td>
              </tr>
            </tbody>
          </template>
          <tbody class="bg-secondary text-white">
            <tr>
              <th class="border-0">Total Credits</th>
              <th class="border-0">{{ getTotalCreditsSum("sms") }}</th>
              <th class="border-0"></th>
              <th class="border-0">{{ getTotalCreditsSum("email") }}</th>
              <th class="border-0"></th>
              <th class="border-0">{{ getTotalCreditsSum("whatsapp") }}</th>
              <th class="border-0"></th>
              <th class="border-0"></th>
            </tr>
          </tbody>
          <template v-if="!packageDetailList.length">
            <tr>
              <td colspan="8">
                <div class="py-5">No Records Found</div>
              </td>
            </tr>
          </template>
        </template>
        <template v-else-if="!tableLoader && packageDetailList.length == 0">
          <tr>
            <td colspan="8">
              <div class="py-5">No Data Found</div>
            </td>
          </tr>
        </template>
      </table>
    </fr-portlet>
  </fr-modal>
</template>
<script>
import {
  FrModal,
  FrPortlet,
  FrPortletHeader,
  FrPortletHeading,
  FrPortletItem,
  FrDateRangePicker,
} from "@froogal.ai/ui";
import { client, urls } from "@/apiClient";
import moment from "moment";
export default {
  name: "package-list",
  props: ["selectedBrand"],
  components: {
    FrModal,
    FrPortlet,
    FrPortletHeader,
    FrPortletItem,
    FrDateRangePicker,
    FrPortletHeading,
  },
  data() {
    return {
      packageDetails: [],
      packageDialog: false,
      PackageDateRange: {
        startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      packageDetailList: [],
      packageSearch: "",
      tableLoader: true,
      packages: [],
    };
  },

  mounted() {
    if(this.selectedBrand?.id){
      this.getPackagedetails();  
    }
  },

  computed: {
    filteredPackages() {
      if (this.packageSearch) {
        const terms = this.packageSearch.split(" ").map((s) => s.toLowerCase());
        return this.packageDetailList.filter((pack) => {
          const lowercasedName = pack.packageName
            ? pack.packageName.toLowerCase()
            : "";
          for (let i = 0; i < terms.length; i++) {
            const term = terms[i];
            if (lowercasedName.includes(term)) {
              return true;
            }
          }
          return false;
        });
      }
      if (this.sort) {
        if (this.sort == "ASC") {
          this.filteredPackages.sort();
        } else if (this.sort == "DESC") {
          this.filteredPackages.sort();
          this.filteredPackages.reverse();
        } else if (this.sort == "") {
          return this.packageDetailList;
        }
      }
      return this.packageDetailList;
    },
  },
  watch: {
    PackageDateRange: function () {
      this.showPackageDetails();
    },
    selectedBrand: function(){
      if(this.selectedBrand?.id){
        this.getPackagedetails();  
      }
    }
  },
  methods: {
    getPackagedetails() {
      client
        .get(urls.creditsData, { params: { brandId: this.selectedBrand.id } })
        .then((response) => {
          this.packageDetails = response.data.packageList;
        });
    },
    showPackageDetails() {
      this.packageDialog = true;
      this.tableLoader = true;
      client
        .get(urls.getMerchantUsageCreditsData, {
          params: {
            brandId: this.selectedBrand?.id,
            startDate: moment(this.PackageDateRange.startDate).format(
              "YYYY-MM-DD"
            ),
            endDate: moment(this.PackageDateRange.endDate).format("YYYY-MM-DD"),
          },
        })
        .then((response) => {
          this.packages = response.data.merchantCreditUsage;
          const groupBy = (array, key) => {
            return array.reduce((result, currentValue) => {
              (result[currentValue[key]] =
                result[currentValue[key]] || []).push(currentValue);
              return result;
            }, {});
          };
          var packageList = groupBy(this.packages, "packageId");
          packageList = Object.entries(packageList);
          var detailCredits = this.packageDetails.map((m) => {
            m.stores = packageList.find((p) => {
              if (p[0] == m.id) {
                return p;
              }
            });

            m.stores = m.stores ? m.stores[1] : [];
            m.stores = Object.entries(groupBy(m.stores, "merchantId"));
            return m;
          });
          this.packageDetailList = detailCredits;
          this.tableLoader = false;
        });
    },
    getData(array, value) {
      var data = array.find((mm) => {
        if (mm.channel == value) {
          return mm;
        }
      });
      return data?.creditsUsed ?? 0;
    },
    getSum(array, value) {
      var sum = 0;
      array.map((m) => {
        if (m.length != 0) {
          m[1].map((c) => {
            if (c.channel.toUpperCase() == value.toUpperCase()) {
              sum = sum + Number(c.creditsUsed);
            }
          });
        }
      });
      return sum;
    },
    getTotalCreditsSum(channal) {
      var sum = 0;
      this.packages.map(item => {
        if(channal.toUpperCase() == item.channel.toUpperCase()) {
          sum = sum + Number(item.creditsUsed);
        }
      })
      return sum;
    }
  },
};
</script>
<style>
.package.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  box-shadow: none;
}

.package.accordion-button::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMjMzLjQgNDA2LjZjMTIuNSAxMi41IDMyLjggMTIuNSA0NS4zIDBsMTkyLTE5MmMxMi41LTEyLjUgMTIuNS0zMi44IDAtNDUuM3MtMzIuOC0xMi41LTQ1LjMgMEwyNTYgMzM4LjcgODYuNiAxNjkuNGMtMTIuNS0xMi41LTMyLjgtMTIuNS00NS4zIDBzLTEyLjUgMzIuOCAwIDQ1LjNsMTkyIDE5MnoiLz48L3N2Zz4=);
}

.package.accordion-button:not(.collapsed)::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBmaWxsPScjMDA1ZWZmJz4KPHBhdGggZD0iTTI1NiAwYTI1NiAyNTYgMCAxIDAgMCA1MTJBMjU2IDI1NiAwIDEgMCAyNTYgMHpNMTM1IDI0MWMtOS40LTkuNC05LjQtMjQuNiAwLTMzLjlzMjQuNi05LjQgMzMuOSAwbDg3IDg3IDg3LTg3YzkuNC05LjQgMjQuNi05LjQgMzMuOSAwczkuNCAyNC42IDAgMzMuOUwyNzMgMzQ1Yy05LjQgOS40LTI0LjYgOS40LTMzLjkgMEwxMzUgMjQxeiIvPjwvc3ZnPg==)
}
</style>
